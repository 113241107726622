<template>
  <section id="projects" class="projects-section">
    <div class="section-name">
      <h2>Projects<span class="colorDot"></span></h2>
    </div>
    <div class="projects">
      <div id="proj1" class="project" v-bind:class="{ active: ToggleActiveA }">
        <button class="btn shadow-none p-0" @click="ToggleActive('A')"><i class="bi bi-arrow-90deg-left"></i></button>

        <h2 class="project-name">Parking Here</h2>
        <div class="project-description">
          <span>This is my newest project. The first standalone backend project using the C# language.
            I improved my knowledge of ASP .NET and Vue.js. The application allows the user to register,
            search for a parking lot in a specific location and then reserve a parking space in the parking spot.
            Parking owners can add and manage parking spots and reservations.</span>
          <div class="project-techs">
            <span class="project-tech salmon">html5</span><span class="project-tech salmon">css3</span><span
              class="project-tech salmon">typescript</span><span class="project-tech salmon">Bootstrap</span>
            <span class="project-tech salmon">Pinia</span><span class="project-tech salmon">Vue.js</span><span
              class="project-tech">mysql</span><span class="project-tech">C#</span><span class="project-tech">ASP .NET
              Core</span>
          </div>
          <div>
            <p class="mt-1">Go to project:<a href="https://parkinghere.kiwwwano.com"><i class="bi bi-tv"></i></a></p>
            <p>Go to github:<a class="ms-2" href="https://github.com/k3gr/ParkingHereApi"><span>BE:</span><i
                  class="bi bi-github"></i>
              </a><a href="https://github.com/k3gr/ParkingHereWebClient"><span>FE:</span><i class="bi bi-github"></i>
              </a></p>
            <p class="mt-2">Demo user:</p>
            <p>l: user_demo@kiwwwano.com</p>
            <p>h: <span class="text-transform-none">Userdemo$123</span></p>
          </div>
        </div>
        <img @click="ToggleActive('A')" src="../assets/images/parking.png" alt="gray wolf racing" />
      </div>
      <div id="proj2" class="project" v-bind:class="{ active: ToggleActiveB }">
        <button class="btn shadow-none p-0" @click="ToggleActive('B')"><i class="bi bi-arrow-90deg-left"></i></button>

        <h2 class="project-name">Gray Wolf Racing</h2>
        <div class="project-description">
          <span>The project brings together players from the E-sports league. This
            is the first web application that I devoted a lot of time to. The
            main assumption was to expand the functionality to facilitate league
            management and to clearly present key information such as the table
            of competitions or the list of players. The application has, a. o.
            player registration system, log in functionality, option to sign up
            to current games. Data of the drivers (users) and competitions is
            stored in a database. The application is in continuous
            development.</span>
          <div class="project-techs">
            <span class="project-tech salmon">html5</span><span class="project-tech salmon">css3</span><span
              class="project-tech salmon">javascript</span><span class="project-tech salmon">thymeleaf</span><span
              class="project-tech">mysql</span><span class="project-tech">spring</span><span
              class="project-tech">docker</span><span class="project-tech">hibernate</span>
          </div>
          <div>
            <p>Go to github:<a class="ms-2" href="https://github.com/k3gr/Gwrt"><span>BE:</span><i
                  class="bi bi-github"></i>
              </a></p>
          </div>
        </div>
        <img @click="ToggleActive('B')" src="../assets/images/gwr.png" alt="gray wolf racing" />
      </div>
      <div id="proj3" class="project" :class="{ active: ToggleActiveC }">
        <button class="btn shadow-none p-0" @click="ToggleActive('C')"><i class="bi bi-arrow-90deg-left"></i></button>

        <h2 class="project-name">Portfolio kiwwwano</h2>
        <div class="project-description">
          <span>During work on the portfolio I learned many new things. One of which is GreenSock Animation Platform.
            It is an easy way to increase websites attractiveness by adding a few animations. In this project
            I also used Sigma for the first time - to design the Kiwwwano animation.</span>
          <div class="project-techs">
            <span class="project-tech salmon">html5</span><span class="project-tech salmon">css3</span><span
              class="project-tech salmon">javascript</span><span class="project-tech salmon">sigma</span><span
              class="project-tech salmon">gsap</span>
          </div>
          <div class="link">
            <span>Go to project:</span><a href="https://kiwwwano.com"><i class="bi bi-tv"></i></a>
          </div>
        </div>
        <img @click="ToggleActive('C')" src="../assets/images/kiwwwano.png" alt="kiwwwano" />
      </div>
      <div id="proj4" class="project" :class="{ active: ToggleActiveD }">
        <button class="btn shadow-none p-0" @click="ToggleActive('D')"><i class="bi bi-arrow-90deg-left"></i></button>
        <h2 class="project-name">Ticket Market</h2>
        <div class="project-description">
          <span>The first project in the Vue Framework. Using the API from TicketMaster, the site displays current
            cultural events.</span>
          <div class="project-techs">
            <span class="project-tech salmon">html5</span><span class="project-tech salmon">css3</span><span
              class="project-tech salmon">javascript</span><span class="project-tech">Vue.js</span>
          </div>
          <div class="link">
            <span>Go to project:</span><a href="https://ticketmarket.kiwwwano.com"><i class="bi bi-tv"></i></a>
          </div>
        </div>
        <img @click="ToggleActive('D')" src="../assets/images/ticketmarket.png" alt="ticketmarket" />
      </div>
    </div>
  </section>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
export default {
  name: "ProjectsComp",
  mounted: function () {
    this.scrollAnimation();
  },
  methods: {
    scrollAnimation() {
      const gs = gsap.timeline({
        scrollTrigger: {
          trigger: "#proj1",
          start: "50% bottom",
        },
      });
      gs.fromTo(
        ".projects-section",
        { opacity: 0 },
        {
          opacity: 1,
          duration: 1.2
        }
      );
      return () => {
      };
    },
    ToggleActive(proj) {
      if (proj === "A") {
        this.ToggleActiveA = !this.ToggleActiveA;
      }
      else if (proj === "B") {
        this.ToggleActiveB = !this.ToggleActiveB;
      }
      else if (proj === "C") {
        this.ToggleActiveC = !this.ToggleActiveC;
      }
      else if (proj === "D") {
        this.ToggleActiveD = !this.ToggleActiveD;
      }
    },
  },
  data() {
    return {
      ToggleActiveA: false,
      ToggleActiveB: false,
      ToggleActiveC: false,
      ToggleActiveD: false,
    };
  },
};
</script>
<style scoped>
.projects {
  margin-bottom: 50px;
}

.project {
  position: relative;
  height: 550px;
  width: 85%;
  left: 0;
  transition: 0.2s linear;
  overflow: hidden;
  border-radius: 10px;
  margin: 30px auto;
}

.project:first-child {
  margin: 0 auto;
}

.project.active.project video,
.project.active.project img {
  filter: saturate(0) blur(4px) brightness(0.6);
  left: 130%;
}

.project:hover.project video,
.project:hover.project img {
  cursor: pointer;
}

.project-description {
  position: relative;
  top: 0;
  left: 0;
  margin: 15px;
  color: #dadada;
  transition: 0.4s linear;
  /* width: 49%; */
  width: 75%;
  height: 100%;
  padding: 0 20px 0 0;
  line-height: 1.3;
}

.project-description span {
  font-size: 22px;
}

.project-tech.salmon {
  background-color: #fd5e5e;
  color: black;
}

.project video,
.project img {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 100%;
  transition: 0.3s linear;
}

.project-name {
  text-transform: uppercase;
  color: #dadada;
  font-size: 60px;
  transition: 0.1s linear;
  position: absolute;
  bottom: 1%;
  padding-right: 20px;
  background-color: black;
  z-index: 1;
}

.project-techs {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  width: 100%;
}

.project-techs span {
  font-size: 14px;
}

.project-tech {
  margin: 2px 2px 10px 2px;
  height: 26px;
  background-color: #003257;
  padding: 5px 10px;
  border-radius: 10px;
  text-transform: uppercase;
}

.project-tech span {
  font-size: 10px;
}

.link {
  position: absolute;
}

.link a,
a {
  text-decoration: none;
  color: #dadada;
  font-family: "Roboto", "Josefin Sans", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  width: 40px;
  height: 40px;
}

.link i,
i {
  color: #ffde5f;
  font-size: 26px;
  margin: 10px;
}

.link span,
p {
  font-size: 17px;
  text-transform: uppercase;
  color: #e5e5e5;
  margin-bottom: 0;
}

.link a:hover i,
a:hover i {
  color: #fd5e5e;
}

.bi-github {
  color: rgb(0, 99, 44);
}

.text-transform-none {
  text-transform: none;
  font-size: 17px !important;
}

@media (max-width: 1100px) and (orientation: portrait) {
  .project {
    width: auto;
    height: 500px;
    margin-bottom: 10px;
    overflow: hidden;
  }

  .project img {
    top: -30%;
  }

  .project:hover.project video,
  .project:hover.project img {
    filter: saturate(1) blur(0px) brightness(1);
    left: 50%;
  }

  .project.active.project video,
  .project.active.project img {
    filter: saturate(0) blur(4px) brightness(0.6);
    left: 300%;
  }

  .project-description {
    width: 100%;
    margin: 5px 0;
    padding: 0;
    /* line-height: 1.3; */
  }

  .project-description span {
    font-size: 16px;
  }

  .project video,
  .project img {
    height: 650px;
    width: auto;
  }

  .project-name {
    padding-right: 10px;
    font-size: 8vw;
    bottom: 0;
    transition: .1s linear;
  }

  .project-techs {
    display: flex;
    flex-wrap: wrap;
    margin-top: 12px;
    width: 100%;
  }

  .projects-section {
    margin: 0;
  }

  span.project-tech {
    margin: 2px 2px 10px 2px;
    font-size: 12px;
    line-height: 10px;
    height: 20px;
  }
}

@media (max-width: 1200px) and (orientation: landscape) {
  .project {
    width: auto;
    height: 420px;
    margin-bottom: 10px;
    overflow: hidden;
  }

  .project:last-child {
    margin: 0;
  }

  .project-description {
    width: 100%;
    margin: 5px 10px;
  }

  .project-description span {
    font-size: 16px;
    line-height: 1;
  }

  .project video,
  .project img {
    width: 100vw;
  }

  .project:hover.project video,
  .project:hover.project img {
    filter: saturate(1) blur(0px) brightness(1);
    left: 50%;
  }

  .project.active.project video,
  .project.active.project img {
    filter: saturate(0) blur(4px) brightness(0.6);
    left: 220%;
  }

  .project-name {
    padding-right: 0;
    font-size: 8vw;
  }

  .project-techs {
    display: flex;
    flex-wrap: wrap;
    margin-top: 12px;
    width: 100%;
  }

  .projects-section {
    margin: 0;
  }

  span.project-tech {
    margin: 2px 2px 10px 2px;
    font-size: 12px;
    line-height: 10px;
    height: 20px;
  }
}

@media (max-width: 330px) and (orientation: portrait) {
  span.strength-description {
    font-size: 14px;
  }

  .project-description {
    padding: 0;
  }

  .project {
    height: 500px;
  }

  .project img {
    width: auto;
    height: 600px;
  }

  .project-description span {
    font-size: 12px;
  }

  .project-techs span {
    font-size: 9px;
    padding: 5px 5px;
  }

  .project.active.project video,
  .project.active.project img {
    filter: saturate(0) blur(0px) brightness(1);
    left: 420%;
  }
}
</style>
