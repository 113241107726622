<template>
  <section id="about" class="about">
    <div class="section-name">
      <h2 class="about-me">Hi<span class="colorDot"></span></h2>
    </div>
    <div class="text-about-me">
      <img class="code" src="../assets/images/pc.png" alt="laptop" /><span>I’m a fullstack developer. I took my first
        steps in programming using Java with Spring Framework, then moved to frontend. The courses I completed are a. o.:
        <span class="italic">"Web developer from scratch"</span>,
        <span class="italic">"Advanced Frontend"</span>,
        <span class="italic">"Javascript Programming"</span>,
        <span class="italic">"ASP.NET Core REST Web API"</span>,
        <span class="italic">Java and Spring Framework courses.</span> Most of the time I spent working with front-end,
        but I feel comfortable in other areas of programming,
        such as back-end as well as databases. For over a year I have been working professionally as a fullstack developer
        using Vue.js connected with Typescript on frontend
        and C# on the backend and I feel comfortable in this area. Scroll down to see my portfolio.
      </span>
    </div>
  </section>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
export default {
  name: "AboutComp",
  mounted: function () {
    this.scrollAnimation();
  },
  methods: {
    scrollAnimation() {
      const gs = gsap.timeline({
        scrollTrigger: {
          trigger: ".about",
          start: "65% bottom",
        },
      });
      gs.fromTo(
        ".about",
        { opacity: 0 },
        {
          opacity: 1,
          duration: 1.2,
        }
      ),
        gs.fromTo(
          ".code",
          { opacity: 0 },
          {
            opacity: 1,
            duration: 1.2,
            delay: -.8
          }
        );
      return () => {
        // gs.scrollTrigger.kill();
      };
    },
  },
};
</script>
<style>
.about {
  border-top: none;
}

.code {
  margin-bottom: 10px;
  width: 50%;
  font-size: 10vw;
  float: right;
  shape-outside: polygon(15.53% 2.36%,
      83.83% 2.25%,
      84.16% 80.2%,
      99.54% 107.1%,
      49.3% 106.85%,
      -9.48% 114.04%,
      15.35% 74.41%);
}

.text-about-me span {
  font-size: 26px;
}

@media (max-width: 1100px) and (orientation: portrait) {
  .text-about-me span {
    font-size: 18px;
  }

  .code {
    width: 50%;
  }
}

@media (max-width: 1200px) and (orientation: landscape) {
  .text-about-me {
    margin: 0 15px;
  }

  .text-about-me span {
    font-size: 18px;
  }

  .code {
    width: 50%;
  }
}

@media (max-width: 1200px) and (orientation: landscape) {
  .text-about-me {
    margin: 0 15px;
  }

  .text-about-me span {
    font-size: 18px;
  }

  .code {
    width: 50%;
  }
}
</style>
