<template>
  <nav class="header" :class="{ sticky: scrollPosition > 450 }">
    <div class="name">
      <svg id="fruit-small" width="44" height="49" viewBox="0 0 44 49" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M37.0871 13.8369L31.5768 7.02572L24.3071 6.33137M37.0871 13.8369L42 13.4071L39.0788 17.0771M37.0871 13.8369L39.0788 17.0771M39.0788 17.0771L41.5021 26.5665L38.249 32.1874M38.249 32.1874L41.1701 36.2542L36.5892 35.5599M38.249 32.1874L36.5892 35.5599M36.5892 35.5599L32.805 41.0816L23.5436 42.5033M23.5436 42.5033L21.3527 47L19.4274 42.5033M23.5436 42.5033H19.4274M19.4274 42.5033L11.5602 41.4122L6.78008 34.6672M6.78008 34.6672L2 35.1631L4.85477 31.2946M6.78008 34.6672L4.85477 31.2946M4.85477 31.2946L2 24.219L5.3195 16.3828M5.3195 16.3828L2.63071 12.6796L7.11203 13.4071M5.3195 16.3828L7.11203 13.4071M7.11203 13.4071L11.5602 7.52167L20.39 6.33137M20.39 6.33137L22.4481 2L24.3071 6.33137M20.39 6.33137H24.3071M7.11203 18.8626L20.0249 25.3762L19.4274 39.2961L13.4191 38.4034L8.90456 31.7906L5.3195 24.7149L7.11203 18.8626ZM22.1162 21.9375L33.3029 15.2256L30.249 10.1337L22.1162 8.94342L14.3817 9.57164L10.4315 14.8619L22.1162 21.9375ZM23.9751 25.3762L35.2946 19.2594L38.249 26.0044L35.2946 31.7906L31.2116 37.6429L24.3071 38.9324L23.9751 25.3762ZM22.1162 18.0691L25.2033 11.8861H20.39L22.1162 18.0691ZM25.2033 17.7384L27.527 11.8861L30.249 14.2998L25.2033 17.7384ZM19.7261 18.0691L17.1369 11.8861L13.4191 14.2998L19.7261 18.0691ZM27.2282 27.1616L34.7635 28.3519L31.9087 32.1874L27.2282 27.1616ZM28.5892 25.1117L34.2324 22.2682L34.7635 26.0044L28.5892 25.1117ZM26.1992 29.6084L30.8133 34.072L26.7303 36.2542L26.1992 29.6084ZM16.8714 26.8641L12.3568 32.9148L9.60166 28.3519L16.8714 26.8641ZM14.3817 24.7149L8.90456 22.2682V26.0044L14.3817 24.7149ZM17.8672 30.1043L17.3693 36.0889L14.3817 34.3365L17.8672 30.1043Z"
          stroke="black" />
      </svg>
      <h2 class="page-name"><a href="https://kiwwwano.com">kiwwwano</a></h2>
    </div>
    <ul>
      <li>
        <a href="#technologies">Skills<span class="tinyDot"></span></a>
      </li>
      <li>
        <a href="#projects">Projects<span class="tinyDot"></span></a>
      </li>
      <li>
        <a href="#contact">Contact<span class="tinyDot"></span></a>
      </li>
      <ol class="lang">
        <li><a href="https://kiwwwano.com">pl</a></li>
      </ol>
    </ul>
  </nav>
</template>
<script>
export default {
  name: "NavHeader",
  data() {
    return {
      scrollPosition: null,
    };
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
};
</script>
<style>
.menu-burger {
  position: absolute;
  right: -100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  transition: all 0.4s ease-in-out;
  cursor: pointer;
  z-index: 2;
}

.burger {
  width: 50px;
  height: 6px;
  background-color: #dadada;
  transition: all 0.4s ease-in-out;
}

.burger::before,
.burger::after {
  content: "";
  position: absolute;
  width: 50px;
  height: 6px;
  background-color: #dadada;
  border-radius: 5px 5px 0 0;
  transition: all 0.4s ease-in-out;
}

.burger::after {
  border-radius: 0 0 5px 5px;
  transform: translateX(-5px);
}

.burger::before {
  transform: translateY(-17px);
}

.burger::after {
  transform: translateY(17px);
}

.menu-burger.active .burger {
  transform: translateX(50px);
  background-color: transparent;
}

.menu-burger.active .burger::before {
  transform: rotate(45deg) translate(-35px, 35px);
  border-radius: 5px;
}

.menu-burger.active .burger::after {
  transform: rotate(-45deg) translate(-35px, -35px);
  border-radius: 5px;
}

.header {
  position: fixed;
  height: 140px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  top: -160px;
  font-size: 30px;
  font-family: "Josefin Sans", "sans-serif";
  animation: move-down 0.5s linear forwards;
  animation-delay: 4.5s;
  z-index: 5;
  transition: 0.3s linear;
}

.header.sticky {
  height: 70px;
  background-color: rgb(0, 0, 0);
  border-bottom: 1px solid #0f0f0f;
}

.header.sticky .name {
  transform: scale(0.7);
}

.header ul {
  display: flex;
  text-transform: uppercase;
  list-style: none;
  z-index: 1;
  margin: 0;
  padding: 0;
}

.header ul li {
  margin: 0 10px;
  border-right: 2px solid #fd5e5e;
  transition: 0.1s linear;
  font-size: 23px;
  height: 30px;
}

.header ul li a {
  text-decoration: none;
  color: #fd5e5e;
  font-weight: bold;
  padding: 6px;
}

.header ul li:hover {
  transform: translateY(5px);
}

.header ul li a:hover {
  color: #dadada;
  outline: 2px solid #fd5e5e;
  border-radius: 5px 0 5px 0;
}

.header ul li a:hover .tinyDot {
  background-color: #fd5e5e;
}

.lang {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 2%;
  width: 100px;
}

.header ol {
  display: inline-flex;
  list-style: none;
}

.header ol li {
  position: relative;
  width: 40px;
  height: 40px;
  border-left: 1px solid rgb(80, 80, 80);
  border-right: 1px solid rgb(80, 80, 80);
  border-radius: 90px;
  background-color: black;
}

.header ol.lang li a {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgb(80, 80, 80);
  font-size: 18px;
  transition: all 0.4s ease-in-out;
  text-align: center;
}

.header ol.lang li a:hover {
  color: rgb(138, 138, 138);
  border-radius: 10px;
}

.header ol li:hover {
  transform: translateY(0px);
}

.header ol li a:hover {
  color: #dadada;
  outline: none;
  border-radius: 0;
}

.name {
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: 0.3s linear;
  line-height: 1;
}

.name h2 {
  margin: 0 0 0 10px;
  font-weight: bold;
}

.name h2:hover a {
  color: rgba(255, 82, 82, 0.8);
}

.name a {
  text-decoration: none;
  color: #fd5e5e;
  font-size: 50px;
}

@media (max-width: 1100px) and (orientation: portrait) {
  .header {
    position: absolute;
    top: 0;
    animation: none;
    height: 100vh;
  }

  .name {
    position: absolute;
    margin-top: 60px;
    height: 160px;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    top: -260px;
    animation: move-down 0.5s linear forwards;
    animation-delay: 3.7s;
    transition: 0.3s linear;
  }

  .name a {
    font-size: 44px;
  }

  .header ul {
    position: absolute;
    left: 160%;
    transform: translatex(-50%);
    flex-direction: column;
    transition: all 0.5s ease-in-out;
    background-color: black;
    height: 101vh;
    width: 101%;
    justify-content: center;
  }

  .header.active ul {
    left: 50%;
  }

  .header.sticky {
    position: absolute;
    top: 0;
    animation: none;
    height: 100vh;
    background-color: transparent;
  }

  .header ul li {
    margin: 35px 0;
    border: none;
    font-size: 39px;
    width: 100vw;
    text-align: center;
  }

  .header ul li:hover {
    transform: translateY(0);
  }

  .header ul li a:hover {
    color: #fd5e5e;
    outline: none;
    border-radius: 0;
  }

  .lang {
    top: 80%;
    right: 50%;
    transform: translateX(50%);
    width: unset;
    padding: 0;
  }

  .header ol li {
    position: relative;
    width: 40px;
    height: 40px;
    border-left: 1px solid rgb(80, 80, 80);
    border-right: 1px solid rgb(80, 80, 80);
    border-radius: 90px;
    background-color: black;
  }

  @keyframes show-up {
    to {
      bottom: 120px;
    }
  }
}

@media (max-width: 1200px) and (orientation: landscape) {
  .menu-burger {
    right: 0;
  }

  .header {
    position: absolute;
    top: 0;
    animation: none;
    height: 100vh;
  }

  .name {
    position: absolute;
    margin-top: 0px;
    height: 160px;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    top: -260px;
    animation: move-down 0.5s linear forwards;
    animation-delay: 3.7s;
    transition: 0.3s linear;
  }

  .name a {
    font-size: 44px;
  }

  .header ul {
    position: absolute;
    left: 160%;
    transform: translatex(-50%);
    flex-direction: column;
    transition: all 0.5s ease-in-out;
    background-color: black;
    height: 101vh;
    width: 101%;
    justify-content: center;
  }

  .header.active ul {
    left: 50%;
  }

  .header.sticky {
    position: absolute;
    top: 0;
    animation: none;
    height: 100vh;
    background-color: transparent;
  }

  .header ul li {
    margin: 15px 0;
    border: none;
    font-size: 39px;
    width: 100vw;
    text-align: center;
  }

  .lang {
    top: 80%;
    right: 50%;
    transform: translateX(50%);
    width: unset;
    padding: 0;
  }

  .header ol li {
    position: relative;
    width: 40px;
    height: 40px;
    border-left: 1px solid rgb(80, 80, 80);
    border-right: 1px solid rgb(80, 80, 80);
    border-radius: 90px;
    background-color: black;
  }

  span.tinyDot {
    display: none;
  }
}

@keyframes show-up {
  to {
    bottom: 120px;
  }
}
</style>
